$font-family: Verdana, sans-serif;
$text-light: #777;

$grey-light: #575756;
$grey-dark: #222222;

$white: #fff;
$primary: #004b95;

$site-spacing-x: 95px;
$site-spacing-x-mobile: 30px;
$container-width: 1250px;

$breakpoint-mobile: 950px;

@mixin mobile {
  @media all and (max-width: $breakpoint-mobile) {
    @content;
  }
}
