@import "@/styles/_vue-globals.scss";






































































































































































.map {
    min-height: 600px;
    height: 60vh;
    background: #eee;

    :global(.gm-style-iw.gm-style-iw-c) {
        border-radius: 0;
        width: 300px;
    }
}

.info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: $text-light;
    font-size: 14px;
    //font-family: $font-family;

    a {
        color: $text-light;
        text-decoration: none;
        font-size: 16px;
        display: block;
        position: relative;
        padding-right: 25px;

        &:after {
            content: "";
            position: absolute;
            display: inline-block;
            top: 3px;
            right: 0;
            background-size: contain;
            background: url("/assets/icons/chevron-right.svg") no-repeat center center;
            height: 14px;
            width: 12px;
        }
    }
}
