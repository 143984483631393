html,
body {
  height: 100%;
  font-family: $font-family;
  padding: 0;
  margin: 0;
  font-size: 16px;
}

html {
  overflow-x: hidden;
}

.root {
  height: inherit;
  display: flex;
  flex-direction: column;

  main {
    flex: 1 0 auto;
  }
}

img {
  max-width: 100%;
}

* {
  //box-sizing: border-box;
}

.fial--locations {
  padding: 60px $site-spacing-x 60px $site-spacing-x !important;

  @include mobile {
    padding: 60px $site-spacing-x-mobile 60px $site-spacing-x-mobile !important;
  }
}
