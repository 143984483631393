@import "@/styles/_vue-globals.scss";

















































































$text-color: $text-light;
$background: #fff;
$padding-x: 15px;
$padding: 11px $padding-x;
$border-radius: 3px;
$select-height: 42px;

.wrapper {
    position: relative;
    width: 100%;
    outline: none;
}

.select {
    position: relative;
    background-color: #fff;
    border-radius: $border-radius;
    border: 1px solid #ddd;
    color: $text-color;
    user-select: none;
    padding: 0 50px 0 $padding-x;
    height: $select-height;
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: bold;
    font-size: 16px;

    @include mobile {
        font-size: 12px;
    }

    &:global(.value) {
        border-color: #777;
    }

    .label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.icon {
    position: absolute;
    display: inline-block;
    top: 0;
    bottom: 0;
    right: 20px;
    background-size: contain;
    background: url("/assets/icons/filter-down.svg") no-repeat center center;
    width: 14px;
}

.reset {
    cursor: pointer;
    background: url("/assets/icons/filter-close.svg") no-repeat center center;
}

.items {
    position: absolute;
    top: 1px;
    left: 0;
    right: 0;
    background: $background;
    z-index: 9999;
    border: 1px solid #ddd;
    border-radius: $border-radius;
    font-size: 14px;
    color: $text-light;
    margin-top: -1px;
    box-shadow: 0 0 10px 0px #c3c3c3;

    @include mobile {
        font-size: 12px;
    }
}

.option {
    color: $text-color;
    cursor: pointer;
    user-select: none;
    padding: $padding;

    &:hover, &:global(.selected) {
        color: #222;
        background-color: #ddd;
    }
}

.hidden {
    visibility: hidden;
    pointer-events: none;
    user-select: none;
    height: 0;
}
