@import "@/styles/_vue-globals.scss";














































































































































































































































//noinspection SassScssResolvedByNameOnly
@import "./styles/components/grid";

@keyframes pulse {
    0% {
        opacity: 1;
    }

    70% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.app {
    position: relative;

    &.loading {
        height: 80vh;
        background: #eee;
    }

    :global(*) {
        box-sizing: border-box;
    }

    --fial-font-size-base: 16px;
    --fial-font-size-search: calc(var(--fial-font-size-base) * 0.875);
    --fial-font-size-search-mobile: calc(var(--fial-font-size-search) * 0.8571);
    --fial-font-size-title: calc(var(--fial-font-size-base) * 1.75);
    --fial-font-size-title-mobile: calc(var(--fial-font-size-title) * 0.7142);

    --fial-text-color: #777;
    --fial-text-color-hover: #222;
}

.skeleton {
    margin-top: 5px;
    display: block;
    width: 400px;
    max-width: 100%;
    height: 35px;
    background: #eee;
    border-radius: 3px;
    animation: pulse 1.2s infinite;
}

.filters {
    display: flex;
    position: absolute;
    top: 10px;
    z-index: 9999;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 100%;

    @include mobile {
        top: 10px;
        left: 10px;
        right: 50px;
        transform: unset;

        .custom-select {
            flex: 0 auto;
        }
    }
}

.canton {
    width: 250px;

    @include mobile {
        width: calc(50% - 10px);

        :global(.items) {
            width: calc(200% + 10px);
            left: 0;
        }
    }
}

.branch {
    width: 350px;
    margin-left: 30px;

    @include mobile {
        width: calc(50% - 10px);
        margin-left: 10px;

        :global(.items) {
            width: calc(200% + 10px);
            left: calc(-100% - 10px);
        }
    }
}

.search {
    float: right;

    @media all and (max-width: 500px) {
        float: left;
        width: 100%;
        padding: 10px 0;
    }

    input {
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 3px;
        font-size: 14px;
        font-size: var(--fial-font-size-search);
        color: $text-light;
        color: var(--fial-text-color);
        padding: 10px 5px 10px 15px;
        width: 100%;

        @include mobile {
            font-size: 12px;
            font-size: var(--fial-font-size-search-mobile);
        }
    }
}

.withArrow {
    padding-right: 21px;

    &:after {
        content: "";
        position: absolute;
        display: inline-block;
        top: 3px;
        right: 0;
        background-size: contain;
        background: url("/assets/icons/chevron-right.svg") no-repeat center center;
        height: 14px;
        width: 12px;
    }
}

.locations {
    width: 100%;
    color: $text-light;
    color: var(--fial-text-color);
    padding: 60px 0 60px 0;

    > div > .title {
        h2, > * {
            //font-family: $font-family;
            font-size: 28px;
            font-size: var(--fial-font-size-title);

            @include mobile {
                font-size: 20px;
                font-size: var(--fial-font-size-title-mobile);
            }
        }

        span {
            margin-left: 5px;
        }

        > * {
            display: inline-block;
            margin-block-start: 0;
            margin-block-end: 0;
            margin: 0;
        }

        margin-bottom: 30px;
    }

    table {
        border-collapse: collapse;
        border-spacing: 20px;
        width: 100%;
        font-size: 16px;
        font-size: var(--fial-font-size-base);

        tr {
            border-top: 1px solid #ddd;

            @include mobile {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }

        tr:last-of-type {
            border-bottom: 1px solid #ddd;
        }

        td {
            vertical-align: top;
            padding: 15px 15px;
            color: $text-light;
            color: var(--fial-text-color);

            &:first-of-type {
                padding-left: 0;
            }

            &:last-of-type {
                padding-right: 0;
            }

            @include mobile {
                display: block;
                padding: 5px 0;

                &:first-of-type {
                    padding-top: 20px;
                }

                &:last-of-type {
                    padding: 20px 0;
                }
            }

            a {
                text-decoration: none;
                color: $text-light;
                color: var(--fial-text-color);
                position: relative;
                display: block;

                &:hover {
                    color: #222;
                    color: var(--fial-text-color-hover);
                }
            }
        }

        td:first-of-type {
            font-weight: 600;
        }
    }
}
