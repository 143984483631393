@import "../variables";

footer {
  font-size: 16px;
  line-height: 1.5;

  &, a {
    color: $white;
  }

  a {
    text-decoration: none;
  }

  p {
    margin-block-start: 20px;
    margin-block-end: 20px;
  }

  .heading {
    font-size: 20px;
    font-weight: bold;
  }

  &.footer {
    padding: 50px $site-spacing-x;
    background: $grey-light;

    @include mobile {
      padding: 30px $site-spacing-x-mobile;
    }
  }

  &.sub-footer {
    padding: 20px $site-spacing-x;
    background: $grey-dark;
    color: #ddd;

    @include mobile {
      padding: 20px $site-spacing-x-mobile;
    }
  }
}
