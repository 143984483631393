@import "../variables";

header {
  background: #fff;
  padding: 20px 95px;

  display: flex;
  align-items: center;

  > * {
    max-width: 100%;
  }

  @include mobile {
    flex-direction: column;
    padding: 0;
    width: 100%;
  }

  .logo {
    flex: 1;

    @include mobile {
      order: 2;
      padding: 0 30px;
      margin: 20px 0;
    }
  }

  .title {
    text-align: center;
    flex: 1;

    @include mobile {
      order: 3;
      padding: 0 30px;
      margin-bottom: 20px;
    }

    h1 {
      font-size: 24px;
      color: $text-light;

      @include mobile {
        margin-top: 0;
        font-size: 20px;
      }
    }

    .language {
      display: inline;
      color: $text-light;
      font-size: 12px;
    }

    a {
      color: $text-light;

      &.active {
        text-decoration: none;
        color: $primary;
      }
    }

    .language + .language:before {
      content: ' | ';
      display: inline;
    }
  }

  .return-button {
    flex: 1;

    .button {
      float: right;

      &.arrow-left::before {
        display: none;
        background-image: url("/assets/icons/chevron-right.svg");
      }

      @include mobile {
        &.arrow-left::before {
          display: inline-block;
        }

        &.arrow-right::after {
          display: none;
        }
      }
    }

    @include mobile {
      order: 1;

      &, .button {
        width: 100%;
      }

      .button {
        float: unset;
        border-radius: 0;
        background: #ddd;
        color: $text-light;
        font-size: 14px;
      }
    }
  }
}
