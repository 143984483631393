@import "../variables";

.button {
  display: block;
  background: $primary;
  padding: 12px 15px;
  color: $white;
  text-decoration: none;
  font-weight: 700;
  border-radius: 3px;
  font-size: 16px;
  line-height: 1;

  &.arrow-left::before {
    content: "";
    background-image: url("/assets/icons/chevron-right-white.svg");
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 7px;
    height: 13px;
    margin-right: 12px;
    transform: rotate(180deg);
  }

  &.arrow-right::after {
    content: "";
    background-image: url("/assets/icons/chevron-right-white.svg");
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 7px;
    height: 12px;
    margin-left: 12px;
  }
}
